export default {
    "table": {
        "no_rows": "Нет данных",
        "settings": "Настройки таблицы",
        "columns": "Столбцы",
        "fixed": "Фиксация",
        "all": "Все",
        "default": "По умолчанию",
        "confirm": "Подтвердить",
        "getDataError": "Не удалось получить данные",
        "size": "Размер",
        "small": "Маленький",
        "middle": "Средний",
        "large": "Большой"
    }
}